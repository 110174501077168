<template lang="pug">
v-timeline.space-y-6(
  dense
  clipped
)
  v-timeline-item(
    class="items-center"
    :icon="history.icon"
    :color="history.color.text"
    :key="idx"
    v-for="(history, idx) in expenseHistories"
  )
    .flex-shrink-1.text-sm.font-semibold.ml-4 {{ $day(history.createdAt).format('MMM Do @ h:mm a') }}
    .flex-grow-1.d-flex.flex-column.ml-4
      .text-sm.font-semibold.mt-2
        span( :class="[history.color.text + '--text']" ) {{ history.action }}
      .text-sm.text-gray-500
        | by {{ history.user.fullName }}
      .d-flex.align-baseline( v-if="history.note" )
        v-icon.mr-2.mt-1( x-small v-if="history.status !== 'submitted'" ) mdi-comment
        .mt-1.text-xs.text-gray-400 {{ history.note }}
</template>

<script>
export default {
  name: 'ExpenseHistoryTimeline',

  props: ['expenseHistories'],

  setup() {}
}
</script>